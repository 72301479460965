/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/codemirror@5.62.3/lib/codemirror.min.js
 * - /npm/codemirror@5.65.3/mode/htmlmixed/htmlmixed.min.js
 * - /npm/codemirror@5.62.3/mode/xml/xml.min.js
 * - /npm/codemirror@5.62.3/mode/css/css.min.js
 * - /npm/codemirror@5.62.3/mode/javascript/javascript.min.js
 * - /npm/codemirror@5.62.3/addon/fold/indent-fold.min.js
 * - /npm/codemirror@5.62.3/mode/php/php.min.js
 * - /npm/codemirror@5.62.3/addon/edit/closebrackets.min.js
 * - /npm/codemirror@5.62.3/addon/edit/closetag.min.js
 * - /npm/codemirror@5.62.3/addon/hint/show-hint.min.js
 * - /npm/codemirror@5.62.3/addon/hint/xml-hint.min.js
 * - /npm/codemirror@5.62.3/addon/hint/css-hint.min.js
 * - /npm/codemirror@5.62.3/addon/lint/lint.min.js
 * - /npm/codemirror@5.62.3/addon/lint/javascript-lint.min.js
 * - /npm/codemirror@5.62.3/addon/lint/json-lint.min.js
 * - /npm/codemirror@5.62.3/addon/lint/css-lint.min.js
 * - /npm/codemirror@5.62.3/addon/lint/html-lint.min.js
 * - /npm/js-beautify@1.14.0/js/lib/beautify-html.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
